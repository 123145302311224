<template>
  <!-- 协议列表 -->
  <div>
    <headbox title="服务相关协议"></headbox>
    <van-list>
      <van-cell
        v-for="item in policyList"
        :key="item.id"
        :title="item.title"
        is-link
        :to="{ name: 'policy', query: { type: item.type, title: item.title } }"
      />
    </van-list>
    <!-- <blog-post v-bind="policyType"></blog-post> -->
  </div>
</template>
<script>
import headbox from "@/components/head.vue";
import * as commonpro from "@/utils/api";
export default {
  data() {
    return {
      policyType: "",
      activeNames: ["1"],
      policyList: [
        { id: 0, title: `${this.GLOBAL}用户注册协议`, type: "REGISTER" },
        { id: 1, title: `${this.GLOBAL}用户隐私政策`, type: "PRIVACY_POLICY" },
        { id: 2, title: `${this.GLOBAL}定价协议`, type: "LOCK_PRICE" },
        { id: 3, title: `${this.GLOBAL}存料协议`, type: "STORAGE" },
        { id: 4, title: `${this.GLOBAL}运输与保险协议`, type: "TRANSPORT_POLICY" }
      ]
    };
  },
  mounted() {
    // commonpro.queryFrequentlyQuestionList().then(res => {
    // this.problemlist = res.data;
    //   debugger
    //     console.log(this.problemlist);
    //   });
  },

  getIndex(policyType) {
    policyType = type;
  },

  components: {
    headbox
  }
};
</script>
<style lang="scss" scoped="true">
::v-deep .van-collapse-item__title:after {
  content: none !important;
}
::v-deep .van-collapse-item__content {
  padding: 0;
}
::v-deep .van-collapse {
  margin: 0 30px;
}
::v-deep .van-icon {
  font-size: 36px;
  margin-top: 35px;
  transform: translate(-50%);
}
::v-deep .van-cell {
  border-bottom: 1px solid #cccccc;
  padding: 0;
}
::v-deep .van-cell__title {
  color: #292929;
  font-size: 32px;
  // margin: 0 30px;
  padding: 35px 0;
  padding-left: 20px;
}
.maxcenter {
  line-height: 48px;
  // margin: 0 30px;
  padding: 30px 0;
  font-size: 26px;
  border-bottom: 1px solid #cccccc;
}
.silderarrow {
  transform: rotate(180deg);
}
.list {
}
.cell > div {
  //  width: 90%;
  //  text-align: right;
  margin-left: 0.3rem;
  //  text-align: center;
  //   vertical-align: middle;
}
</style>
