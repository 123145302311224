var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("headbox", { attrs: { title: "服务相关协议" } }),
      _c(
        "van-list",
        _vm._l(_vm.policyList, function(item) {
          return _c("van-cell", {
            key: item.id,
            attrs: {
              title: item.title,
              "is-link": "",
              to: {
                name: "policy",
                query: { type: item.type, title: item.title }
              }
            }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }